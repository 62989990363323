<template>
  <div>
    <div class="login-form">
      <h2>大佛禅院登记页</h2>
      <form @submit.prevent="login">
        <label for="phone" style="text-align: left; font-size: 18px;">手机号码:</label>
        <input type="text" style="text-align: left; font-size: 18px;" id="phone" v-model="phone" required />

        <button type="button" @click="handleSendVerificationCode" :disabled="isButtonDisabled" style="font-size: 18px; padding: 10px 20px;">
  <template v-if="isButtonDisabled">
    倒计时 {{ countdown }} 秒
  </template>
  <template v-else>
    发送验证码
  </template>
</button>

        <label for="phoneCode" style="text-align: left; font-size: 18px;">手机验证码:</label>
        <input type="text" id="phoneCode" style="text-align: left; font-size: 18px;" v-model="phoneCode" required />
        <label for="imageCode" style="text-align: left; font-size: 18px;">图片验证码:</label>
        <input type="text" id="imageCode" style="text-align: left; font-size: 18px;" v-model="imageCode" required />
        <button type="button" id="imageCodebutton" @click="fetchImageCode">
     <!-- 点击按钮重新加载验证码 -->
     <img :src="image" alt="Image Code" />
   </button>
        <label for="realname" style="text-align: left; font-size: 18px;">游客姓名:</label>
        <input type="text" id="realname" style="text-align: left; font-size: 18px;" v-model="realname" required />
        <button id="subitbutton" type="submit" style="font-size: 18px; padding: 10px 18px;">登录</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import QRCode from 'qrcodejs2';

export default {
  name: 'LoginPage',
  components: {
  },
  data() {
    return {
      phone: '',
      phoneCode: '',
      imageCode: '',
      captchaId: '', // 存放图片验证码ID
      countdown: 0, // 倒计时秒数
      isButtonDisabled: false, // 按钮是否禁用
      image: '', // 存放base64格式的图片验证码
      qrcodeData: "null", // 存储二维码数据
      pageTitle: '大佛禅院登记页',
    };
  },
  computed: {
    sliderItems() {
      // 幻灯片数据
      return [
        {
          image: 'https://example.com/slide1.jpg',
        },
        {
          image: 'https://example.com/slide2.jpg',
        },
        {
          image: 'https://example.com/slide3.jpg',
        },
      ];
    },
  },
  methods: {
    updatePageTitle(newTitle = this.pageTitle) {
      document.title = newTitle;
    },
    validatePhoneNumber(phone) {
      const phonePattern = /^\d{11}$/;
      return phonePattern.test(phone);
    },
    handleSendVerificationCode() {
      if (!this.phone) {
        alert('请输入手机号'); // 弹出提示：请输入手机号
      } else if (!this.validatePhoneNumber(this.phone)) {
        alert('请输入正确手机号'); // 弹出提示：请输入正确手机号
      } else {
        this.sendVerificationCode();
      }
    },
    sendVerificationCode() {
      const params = {
        code: this.phoneCode,
        codeId: this.captchaId,
        phone: this.phone,
      };

      axios.post('https://dfcyregserver.ofdo.net/sendsms/register', params, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          console.log(response.data);

          // 发送成功后执行倒计时
          const { data } = response;
          if (data.code !== 200) {
            console.error('Failed to send verification code:', data.msg);
            return;
          } else {
            this.isButtonDisabled = true; // 禁用按钮
            this.countdown = 60; // 设置倒计时秒数为60

            const timer = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown--; // 每秒减少倒计时秒数
              } else {
                clearInterval(timer); // 倒计时结束，清除计时器
                this.isButtonDisabled = false; // 启用按钮
              }
            }, 1000);
            console.log('发送成功');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showQRCode() {
      /* eslint-disable no-unused-vars */
  const qrcodeElement = document.createElement('div');
  const qrcode = new QRCode(qrcodeElement, {
    text: this.qrcodeData,
    width: 300,
    height: 300,
    correctLevel:QRCode.CorrectLevel.M,
    typeNumber:4

  });
  /* eslint-enable no-unused-vars */
  // 创建一个按钮用于保存二维码
  const saveButton = document.createElement('button');
  saveButton.textContent = '保存二维码';
  saveButton.addEventListener('click', () => {
    const canvas = qrcode._el.firstChild; // 获取二维码的Canvas元素
    const url = canvas.toDataURL('image/png'); // 将Canvas转换为图片URL

    const a = document.createElement('a');
    a.href = url;
    a.download = 'qrcode.png'; // 设置下载文件名
    a.click(); // 触发下载
  });
  // 创建一个遮罩层
  const overlay = document.createElement('div');
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100%';
  overlay.style.height = '100%';
  overlay.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
  overlay.style.display = 'flex';
  overlay.style.justifyContent = 'center';
  overlay.style.alignItems = 'center';
  overlay.style.zIndex = '9999';

  // 点击遮罩层关闭


  // 将二维码元素添加到遮罩层中
  overlay.appendChild(qrcodeElement);
  
  // 将遮罩层添加到页面中
  document.body.appendChild(overlay);
},

login() {
  // 验证参数格式
  const codeFormatError = !/^\d{6}$/.test(this.phoneCode);
  const phoneFormatError = !/^1[3-9]\d{9}$/.test(this.phone);
  const realnameFormatError = !this.realname ||
                            (this.realname && this.realname.trim().length < 2) ||
                            !/^[\u4e00-\u9fa5]+$/i.test(this.realname);
  let errorMessage = '';

  if (codeFormatError) {
    errorMessage += "验证码为 6 位数字\n";
  }
  if (phoneFormatError) {
    errorMessage += "手机号码格式不正确\n";
  }
  if (realnameFormatError) {
    errorMessage += "请输入正确姓名\n";
  }

  if (errorMessage) {
    // 参数格式有误，弹出警告并终止请求发送
    alert(errorMessage);
    return;
  }

  const params = {
    code: this.phoneCode,
    phone: this.phone,
    realname: this.realname,
    captchaId: this.captchaId,
    captchaCode: this.imageCode,
  };

  axios.post('https://dfcyregserver.ofdo.net/api/sendTicket', params, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      // 处理服务器的响应
      console.log(response.data);
      if (response.data.code !== 200) {
        alert(response.data.message);
        return;
      }
      this.qrcodeData = response.data.message; // 将二维码数据赋值给 qrcodeData 属性
      this.showQRCode();
    })
    .catch((error) => {
      console.error(error);
    });
},

    fetchImageCode() {
      axios.post('https://qcjx.ofdo.net/api/captcha/captcha')
        .then((response) => {
          const { data } = response;
          if (data.code === 0) {
            this.captchaId = data.data.id;
            this.image = data.data.img;
          }
        })
        .catch((error) => {
          console.error('Failed to fetch image code:', error);
        });
    },
  },
  watch: {
    pageTitle(newTitle) {
      this.updatePageTitle(newTitle);
    },
  },
  mounted() {
    this.fetchImageCode(); // 页面加载时获取图片验证码
    this.updatePageTitle();
  },

};
</script>
<style scoped>
     #imageCodebutton {
       width: 100%; /* 将宽度设置为100%，使其自适应 */
       height: auto; /* 高度自适应，根据内容调整 */
       display: inline-block; /* 或者使用 block，取决于您的布局需求 */
     }

     #imageCodebutton img {
       width: 100%;
       height: auto;
       object-fit: fill;
     }
</style>

<style>
  /* 定义全局 CSS 变量 */
  :root {
    --primary-color: #1890ff; /* 蓝色主题色 */
    --font-family: 'Roboto', sans-serif; /* 自定义字体 */
    --input-border-radius: 4px; /* 输入框圆角 */
    --form-shadow: 0 .png var(--input-border-radius) rgba(0, 0, 0, 0.08); /* 表单阴影 */
    --button-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 按钮阴影 */
  }

  /* Slider styles */
  .slider {
    margin-bottom: 20px;
  }

  /* Login form styles */
  .login-form {
    max-width: 400px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: var(--input-border-radius);
    box-shadow: var(--form-shadow);
    padding: 20px;
  }

  /* Form layout */
  form {
    display: flex;
    flex-direction: column;
    gap: 10px; /* 代替 margin-top */
  }

  /* Label styles */
  label {
    font-family: var(--font-family);
    font-size: 14px;
    color: #333;
  }

  /* Input styles */
  input {
    font-family: var(--font-family);
    font-size: 16px;
    color: #333;
    border: 1px solid #e8e8e8;
    border-radius: var(--input-border-radius);
    padding: 10px 15px;
    transition: border-color 0.2s ease;

    &:focus {
      outline: none;
      border-color: var(--primary-color);
    }
  }

  /* Image styles */
  img {
    max-width: 200px;
    margin-top: 5px;
    margin-bottom: 20px; /* 代替 margin-top，保持与表单底部间距一致 */
  }

  /* Button styles */
  .login-button {
    font-family: var(--font-family);
    font-size: 16px;
    color: #fff;
    background-color: var(--primary-color);
    border-radius: var(--input-border-radius);
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: darken(var(--primary-color), ¼);
    }

    &:active {
      box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15);
    }
  }
</style>
